import React from 'react';

import Layout from "../components/layout.js";
import SEO from "../components/SEO.js";
import NossosProjetos from "../components/nossosProjetos.js";

function Projetos () {
    return (
        <Layout>
            <SEO title="Nossos Projetos" description={`Conheça todos os projetos da Kat Devs!`} />
            <NossosProjetos />
        </Layout>
    );
}

export default Projetos;