import React, {useState, useEffect} from "react";
import { Link } from "gatsby";

import topSecretImg from "../../static/images/topSecret.webp";

function NossosProjetos(props) {
    const [mainColor, setMainColor] = useState(props.mainColor);
    const [secondaryColor, setSecondaryColor] = useState(props.secondaryColor);

    useEffect(() => {
        setMainColor(props.mainColor);
        setSecondaryColor(props.secondaryColor);
    }, [props.mainColor, props.secondaryColor])

    return (
        <div>
            <div className="d-none d-md-block" style={{backgroundColor: mainColor}}>
                <div className="d-flex align-items-center animated delay-0s fadeInDown" style={{backgroundColor: "white", minHeight: "300px", zIndex: "1"}}>
                    <div className="w-100 p-2 text-center animated fadeIn delay-1s">
                        <h1 className="bungee-font">Conheça nossos projetos</h1>
                    </div>
                </div>
            </div>

            <div className="d-block d-md-none" style={{backgroundColor: mainColor}}>
                <div className="animated delay-0s fadeInDown" style={{backgroundColor: "white", zIndex: "1"}}>
                    <div className="p-2 w-100 text-center animated fadeIn delay-1s">
                        <h1 className="bungee-font" style={{lineHeight: "1"}}>Conheça nossos projetos</h1>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: mainColor, color: "white", minHeight: "400px"}}>
                <div className="container" style={{padding: "20px"}}>
                    <div className="text-center">
                        <h2 className="bungee-font">Project Ullr</h2>
                    </div>
                    <div className="d-none d-lg-block">
                        <div className=" d-flex flex-row align-items-center mt-4">
                            <img src={topSecretImg} className="sombra-img img-fluid"  alt="Projeto Ullr"></img>
                            <p className="pl-4" style={{fontSize: "large"}}>
                                O projeto <strong>Project Ullr</strong> <small>(também lido como Project Uler)</small> é nossa mais nova solução com objetivo de reunir Gamers 
                                de diversos jogos, fazendo com que a diversão seja maior e sem toxicidade. Como o projeto está ainda em desenvolvimento,
                                fique ligado em nossas <strong><Link to="/contatos/" style={{color: secondaryColor}}>redes sociais</Link></strong> para ficar 
                                por dentro das novidades.
                            </p>
                        </div>
                    </div>
                    <div className="d-block d-lg-none">
                        <div>
                            <div className="text-center">
                                <img src={topSecretImg} className="sombra-img img-fluid" alt="Projeto Ullr"></img>
                            </div>
                            <p className="pt-4" style={{fontSize: "large"}}>
                                O projeto <strong>Project Ullr</strong> <small>(também lido como Project Uler)</small> é nossa mais nova solução com objetivo de reunir Gamers 
                                de diversos jogos, fazendo com que a diversão seja maior e sem toxicidade. Como o projeto está ainda em desenvolvimento,
                                fique ligado em nossas <strong><Link to="/contatos/" style={{color: secondaryColor}}>redes sociais</Link></strong> para saber das novidades.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: "white", color: secondaryColor, minHeight: "400px"}}>
                <div className="container" style={{padding: "20px"}}>
                    <div className="text-center">
                        <h2 className="bungee-font">Project Hermes</h2>
                    </div>
                    <div className="d-none d-lg-block">
                        <div className=" d-flex flex-row align-items-center mt-4">
                            <p className="pr-4" style={{fontSize: "large"}}>
                                Nosso primeiro game também está em desenvolvimento: <strong>Project Hermes</strong>. Tratando de uma realidade muito bem conhecida pelo brasileiro,
                                nada melhor do que misturar memes e escolhas que alteram todo o rumo de uma nação. Siga a Kat Devs nas 
                                <strong><Link to="/contatos/" style={{color: mainColor}}> redes sociais</Link></strong> para ficar por dentro de tudo.
                            </p>
                            <img src={topSecretImg} className="sombra-img img-fluid" alt="Projeto Hermes"></img>
                        </div>
                    </div>
                    <div className="d-block d-lg-none">
                        <div>
                            <div className="text-center">
                                <img src={topSecretImg} className="sombra-img img-fluid" alt="Projeto Hermes"></img>
                            </div>
                            <p className="pt-4" style={{fontSize: "large"}}>
                                Nosso primeiro game também está em desenvolvimento: <strong>Project Hermes</strong>. Tratando de uma realidade muito bem conhecida pelo brasileiro,
                                nada melhor do que misturar memes e escolhas que alteram todo o rumo de uma nação. Siga a Kat Devs nas 
                                <strong><Link to="/contatos/" style={{color: mainColor}}> redes sociais</Link></strong> para ficar por dentro de tudo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default NossosProjetos;